<template>
  <v-simple-table dense
                  fixed-header
                  height="800px"
  >
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-left column-2">
          id
        </th>
        <th class="text-left column-2">
          Size
        </th>
        <th class="text-left">
          Biz
        </th>
        <th class="text-left">
          State
        </th>
        <th class="text-left column-2">
          Action
        </th>
        <th class="text-left">
          Result
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="item in values"
          :key="item._id"
      >
        <td>{{ item._id }}</td>
        <td>{{ item.size?Math.ceil(item.size/1024):0 }}KB</td>
        <td>{{ item.biz }}</td>
        <td>{{ item.state }}</td>
        <td>
          <div class="d-flex flex-row" style="gap: 4px">
            <v-btn color="success" x-small @click="reOcr(item)">ReOcr</v-btn>
            <dailog-image :url="item.url" class="my-2">Img</dailog-image>
          </div>
        </td>
        <td><ocrResultButton :url="item.url" :values="item.result?item.result.StructuralItems:[]">看结果</ocrResultButton></td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import dailogImage from "@/components/dailog-image";
import ocrResultButton from "@/views/ocr/ocr-result-button";
import cloudbase from "@/plugins/cloudbase";
import db from "@/plugins/db";
export default {
  name: "ocr-list",
  components:{dailogImage,ocrResultButton},
  props:{
    values:{
      type:Array,
      default:()=>{return [
        {
          _id:'123123',
          size:'',
          state:'Done',
          result:{}
        }
      ]}
    }
  },
  data(){
    return {
    }
  },
  methods:{
    reOcr(item){
      this.getText(item.url)
          .then(s=>{
            item.result = s.result
            db.collection('ocr-records')
                .doc(item._id)
                .update({
                  result:s.result,
                  state: '已识别'
                })
          })
    },
    getText(url,columns=['地址','提单号','商品编码','收款日期']) {
      return cloudbase.callFunction({
        name: 'ocr-invoice',
        data: {
          ImageUrl: url,
          ItemNames:columns
        }
      })
    }
  }
}
</script>

<style scoped>
.column-2 {
  width: 2%;
}
</style>