<template>
  <v-dialog
      v-model="dialog"
      width="80vw"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
          x-small
      >
        <slot></slot>
      </v-btn>
      <v-chip
          class="ma-2"
          color="success"
          outlined
      >
        <v-icon left>
          mdi-desktop-mac
        </v-icon>
        {{ keyResult.cashCode }}
      </v-chip>
      <v-chip
          class="ma-2"
          color="success"
          outlined
      >
        <v-icon left>
          mdi-barcode
        </v-icon>
        {{ keyResult.invoice }}
      </v-chip>
    </template>

    <v-card>
      <div class="d-flex flex-row align-start">
        <v-img
            class="flex-shrink-1 flex-grow-1"
            :src="url"
            width="500"
            height="800"
            contain
        ></v-img>
        <ocrResultList :values="values"></ocrResultList>
      </div>
    </v-card>
    <v-btn @click="dialog = false">close</v-btn>
  </v-dialog>
</template>

<script>
import ocrResultList from "@/views/ocr/ocr-result-list";
export default {
  name: "ocr-result-button",
  components:{ocrResultList},
  props:{
    values:{
      type:[],
      default:()=>{return []}
    },
    url:String
  },
  data(){
    return {
      dialog:false
    }
  },
  computed:{
    keyResult(){
      let value = {
        cashCode:'未找到',
        invoice:'未找到'
      }
      this.values.forEach(s=>{
        if (s.Name ==='小票号'){
          value.invoice = s.Value+` (${s.Confidence})`
        }
        if (s.Name ==='收银机号'){
          value.cashCode = s.Value+` (${s.Confidence})`
        }
      })
      return value
    }
  }
}
</script>

<style scoped>

</style>