<template>
  <v-dialog
      v-model="dialog"
      width="70vw"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
          x-small
      >
        <slot></slot>
      </v-btn>
    </template>

    <v-card height="60vh" class="d-flex flex-column">
      <v-card-text class="flex-grow-1 flex-shrink-1 d-flex flex-column align-center justify-center">
        <v-img
            :style="style"
            :src="url"
            :width="width"
            aspect-ratio="1.7"
            contain
        ></v-img>

      </v-card-text>

      <v-card-actions>
        <v-slider
            v-model="width"
            class="align-self-stretch"
            min="300"
            max="1000"
            label="大小"
        ></v-slider>
        <v-slider
            v-model="degree"
            class="align-self-stretch"
            min="0"
            max="360"
            step="10"
            thumb-label
            label="角度"
        ></v-slider>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dailog-image",
  props:['url'],
  data(){
    return {
      dialog:false,
      degree:0,
      width:500
    }
  },
  methods:{
    rotate(){
      this.degree+=45
    }
  },
  computed:{
    style(){
      return { transform: 'rotate('+this.degree+'deg)'}
    }
  }
}
</script>

<style scoped>

</style>