<template>
  <v-container>
    <v-card class="mt-4">
      <v-card-text>
        <v-overlay
            absolute
            :value="loading"
        >
          <v-progress-circular
              indeterminate
              color="purple"
          ></v-progress-circular>

        </v-overlay>

        <v-file-input
            accept="image/*"
            label="File input"
            v-model="files"
            multiple
            counter
        ></v-file-input>
        <v-select
            :items="bizCategories"
            label="业务单据类别"
            dense
            v-model="biz"
        ></v-select>
        <v-textarea
            v-model="textarea"
            @paste.prevent="paste"
        ></v-textarea>
        <v-simple-table>
          <template v-slot:default>
<!--            <thead>-->
<!--            <tr>-->
<!--              <th class="text-left">-->
<!--                Name-->
<!--              </th>-->
<!--              <th class="text-left">-->
<!--                Calories-->
<!--              </th>-->
<!--            </tr>-->
<!--            </thead>-->
            <tbody>
            <tr
                v-for="(row,row_index) in table"
                :key="row_index"
            >
              <td v-for="(cell, cell_index) in row" :key="cell_index">{{ cell }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-btn @click="upload()" :disabled="files.length===0"> 上传识别</v-btn>
        <v-progress-circular
            v-if="files.length>0"
            :rotate="360"
            :value="progress"
            color="teal"
            class="ml-2"
        >
          {{ progress }}
        </v-progress-circular>
      </v-card-text>
    </v-card>
    <v-card class="mt-4">
      <v-card-title>Ocr records</v-card-title>
      <v-card-title>
        <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @click:append="search"
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <ocr-list :values="values" class="mt-4"></ocr-list>
        <div class="text-center">
          <v-pagination
              v-model="page"
              :length="pageSize"
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import cloudbase from "@/plugins/cloudbase";
import ocrResultList from "@/views/ocr/ocr-result-list";
import ocrList from "@/views/ocr/ocr-list";
import db from "@/plugins/db";

export default {
  name: "index",
  components: {ocrResultList, ocrList},
  data() {
    return {
      loading: false,
      files: [],
      bizCategories: ['工贸', '重百'],
      biz: '',
      url: 'https://7365-service-vue-test-2fyjwm0de5dd31b-1308347125.tcb.qcloud.la/kx_temp/20220424-114115.jpeg?sign=4ec93426839f85b9435c5a9d142463c7&t=1650775807',
      text: {
        "Angle": 89.35881042480469,
        "RequestId": "417300c4-c83a-4f5e-88e7-7ebfc3b02fff",
        "StructuralItems": [
          {
            "Confidence": 99,
            "ItemCoord": {
              "Height": 55,
              "Width": 604,
              "X": 529,
              "Y": 222
            },
            "Name": "机构",
            "Value": "重庆重百商社电器有限公司"
          },
          {
            "Confidence": 99,
            "ItemCoord": {
              "Height": 51,
              "Width": 236,
              "X": 713,
              "Y": 300
            },
            "Name": "标题",
            "Value": "专用收据"
          },
          {
            "Confidence": 99,
            "ItemCoord": {
              "Height": 41,
              "Width": 203,
              "X": 1214,
              "Y": 312
            },
            "Name": "收据号码",
            "Value": "02477315"
          },
          {
            "Confidence": 99,
            "ItemCoord": {
              "Height": 31,
              "Width": 154,
              "X": 314,
              "Y": 394
            },
            "Name": "开票日期",
            "Value": "2022年04月18日"
          },
          {
            "Confidence": 99,
            "ItemCoord": {
              "Height": 44,
              "Width": 140,
              "X": 1363,
              "Y": 466
            },
            "Name": "单据状态",
            "Value": "已收银"
          },
          {
            "Confidence": 99,
            "ItemCoord": {
              "Height": 36,
              "Width": 561,
              "X": 369,
              "Y": 482
            },
            "Name": "销售门店",
            "Value": "[3107]重庆重百商社电器有限公司合川店"
          },
          {
            "Confidence": 99,
            "ItemCoord": {
              "Height": 37,
              "Width": 33,
              "X": 1470,
              "Y": 521
            },
            "Name": "打印次数",
            "Value": "1"
          },
          {
            "Confidence": 99,
            "ItemCoord": {
              "Height": 36,
              "Width": 155,
              "X": 330,
              "Y": 535
            },
            "Name": "小票号",
            "Value": "2204181549"
          },
          {
            "Confidence": 99,
            "ItemCoord": {
              "Height": 36,
              "Width": 105,
              "X": 640,
              "Y": 535
            },
            "Name": "收货人",
            "Value": "颜家容"
          },
          {
            "Confidence": 99,
            "ItemCoord": {
              "Height": 28,
              "Width": 429,
              "X": 316,
              "Y": 653
            },
            "Name": "商品名称",
            "Value": "重百延保冰箱延长保修至4年(501-1000)"
          },
          {
            "Confidence": 99,
            "ItemCoord": {
              "Height": 22,
              "Width": 19,
              "X": 1120,
              "Y": 655
            },
            "Name": "销售数量",
            "Value": "1"
          },
          {
            "Confidence": 99,
            "ItemCoord": {
              "Height": 24,
              "Width": 109,
              "X": 177,
              "Y": 657
            },
            "Name": "商品编码",
            "Value": "83006296"
          },
          {
            "Confidence": 99,
            "ItemCoord": {
              "Height": 23,
              "Width": 28,
              "X": 1403,
              "Y": 657
            },
            "Name": "成交金额",
            "Value": "59"
          },
          {
            "Confidence": 99,
            "ItemCoord": {
              "Height": 23,
              "Width": 30,
              "X": 1255,
              "Y": 657
            },
            "Name": "成交价",
            "Value": "59"
          },
          {
            "Confidence": 99,
            "ItemCoord": {
              "Height": 132,
              "Width": 26,
              "X": 1493,
              "Y": 690
            },
            "Name": "第一联",
            "Value": "顾客留存联"
          },
          {
            "Confidence": 99,
            "ItemCoord": {
              "Height": 30,
              "Width": 208,
              "X": 346,
              "Y": 782
            },
            "Name": "发票下载地址",
            "Value": "www. cqdzfp. com"
          },
          {
            "Confidence": 99,
            "ItemCoord": {
              "Height": 32,
              "Width": 293,
              "X": 829,
              "Y": 785
            },
            "Name": "发票查询单号",
            "Value": "100210231072022000662330"
          },
          {
            "Confidence": 99,
            "ItemCoord": {
              "Height": 29,
              "Width": 110,
              "X": 543,
              "Y": 826
            },
            "Name": "客服",
            "Value": "4001001006"
          },
          {
            "Confidence": 99,
            "ItemCoord": {
              "Height": 29,
              "Width": 130,
              "X": 335,
              "Y": 826
            },
            "Name": "门店电话",
            "Value": "023-42816033"
          },
          {
            "Confidence": 99,
            "ItemCoord": {
              "Height": 29,
              "Width": 373,
              "X": 324,
              "Y": 862
            },
            "Name": "温馨提示",
            "Value": "提货卡及第三方支付卡不开具发票。2.顾客至购物之日起(以本票据日期为准)商场将提供100天免费保管服务;超过100天提货商场将按逾期5元/天收取仓库保管费。3.顾客100天后提货，如"
          },
          {
            "Confidence": 99,
            "ItemCoord": {
              "Height": 34,
              "Width": 1249,
              "X": 215,
              "Y": 1031
            },
            "Name": "商品编码",
            "Value": "4.电器商品试用期为30天(电脑、手机、数码商品除外);试用期内发生性能故障顾客可以选择退货、换货或修理(退换商品的内外包装、附件、赠品需齐全;如赠品已消耗，退款中须扣除该赠品价值的货款)"
          },
          {
            "Confidence": 99,
            "ItemCoord": {
              "Height": 31,
              "Width": 92,
              "X": 416,
              "Y": 1088
            },
            "Name": "咨询服务电话",
            "Value": "4001001006"
          }
        ]
      },
      uploaded: 0,
      page: 1,
      pageSize: 1,
      limit: 10,
      searchText: '',
      table:[],
      textarea: '',
      values: [
        {
          _id: '1231234',
          size: '',
          state: 'Done',
          result: {}
        }
      ]

    }
  },
  created() {
    this.search()

  },
  methods: {
    paste(v) {
      const pasteValue = v.clipboardData.getData('text')
      this.table = pasteValue.split('\n')
          .reduce((previousValue, currentValue) => {
                previousValue + currentValue
                const row = currentValue.split('\t')
                    .reduce((p, c) => {
                      p.push(c)
                      return p
                    }, [])
                previousValue.push(row)
                return previousValue
              },
              [])
      console.log(this.table)
    },
    search() {
      this.getQuery()
          .count()
          .then(s => {
            this.pageSize = Math.ceil(s.total / this.limit,)
          })
      this.getRecords()
    },
    getQuery() {
      let query = db.collection('ocr-records')
      if (this.searchText) {
        query = query.where({
          biz: this.searchText
        })
      }
      return query
    },
    getRecords(page = 1) {
      this.getQuery()
          .skip((page - 1) * this.limit)
          .limit(this.limit)
          .get()
          .then(s => {
            this.values = s.data
          })
    },
    upload() {
      this.loading = true
      this.files.forEach(f => {
        cloudbase.uploadFile({
          cloudPath: `ocr-records/${f.name}`,
          filePath: f
        })
            .then(file => {
              console.log(file)
              return db.collection('ocr-records').add({
                size: f.size,
                name: f.name,
                fileID: file.fileID,
                biz: this.biz,
                url: file.download_url,
                state: '待识别',
              }).then(r => {
                console.log(r)
                this.getText(file.download_url)
                    .then(v => {
                      db.collection('ocr-records')
                          .doc(r.id)
                          .update({
                            result: v.result,
                            state: '已识别'
                          })
                          .then(() => {
                            this.uploaded++
                            if (this.uploaded === this.files.length) {
                              this.getRecords()
                              this.loading = false
                              this.files = []
                              this.uploaded = 0
                            }
                          })
                    })
              })
            })

      })
    },
    getText(url, columns = ['地址', '提单号', '商品编码', '收款日期']) {
      return cloudbase.callFunction({
        name: 'ocr-invoice',
        data: {
          ImageUrl: url,
          ItemNames: columns
        }
      })
    }
  },
  computed: {
    progress() {
      return this.uploaded * 100 / this.files.length
    }
  },
  watch: {
    page(v) {
      this.getRecords(v)
    }
  }

}
</script>

<style scoped>

</style>