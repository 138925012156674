<template>
  <v-simple-table class="ocr-table" height="800" fixed-header>
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-left column-30">
          字段
        </th>
        <th class="text-left">
          值
        </th>
        <th class="text-left column-20">
          置信度
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="value in values"
          :key="value.name"
      >
        <td><strong>{{ value.Name }}</strong></td>
        <td>{{ value.Value }}</td>
        <td>{{ value.Confidence }}</td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "ocr-list",
  props:{
    values:{
      type:Array,
      default:()=>{return []}
    }
  }
}
</script>

<style scoped>
.ocr-table {
  width: 400px;
}

.column-20 {
  width: 20%;
}
.column-30 {
   width: 30%;
 }
</style>